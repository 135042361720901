<template>
  <div class="firendList">
    <div
      class="user-item"
      v-for="item in friendlist"
      :key="item.id"
      @click="toChatView(item.id)"
    >
      <div class="user-img">
        <div v-show="item.tip !== '0'" class="new_tip">{{ item.tip }}</div>
        <img :src="item.userimg" alt="" />
      </div>
      <div class="data-wrap">
        <div class="username">{{ item.username }}</div>
        <div class="new-data">{{ item.new_data }}</div>
      </div>
      <div class="date">{{ item.datetime | formatDateTime }}</div>
    </div>
  </div>
</template>

<script>
import myStore from "../../../utils/localStore";
import formatDate from "../../../utils/formatDate";

export default {
  name: "firendList",
  data() {
    return {
      userinfo: myStore.getStore("userinfo"),
      friendlist: [],
    };
  },
  mounted() {
    this.getFriendList();
  },
  filters: {
    formatDateTime(date) {
      return formatDate.format1(date);
    },
  },
  methods: {
    async getFriendList() {
      const { data: res } = await this.$http.get(
        `friend_list/`
      );
      if (res.code !== "200") return;
      this.friendlist = res.data;
    },
    toChatView(id) {
      this.$router.push({
        path: "/home/user/userchat",
        query: { id },
      });
    },
  },
};
</script>

<style lang='less' scoped>
.firendList {
  margin-top: 10px;
  display: flex;
  flex-direction: column;

  .user-item {
    display: flex;
    height: 50px;

    .user-img {
      flex: 2;
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      img {
        width: 32px;
        height: 32px;
      }

      .new_tip {
        position: absolute;
        top: 0px;
        right: 0px;
        width: 16px;
        height: 16px;
        text-align: center;
        border-radius: 50%;
        font-size: 10px;
        color: #fff;
        background: crimson;
      }
    }

    .data-wrap {
      flex: 5;
      .username {
        font-size: 14px;
        margin-top: 7px;
        display: -webkit-box;
        // 不换行
        overflow: hidden;
        -webkit-box-orient: vertical;
        // 俩点S
        -webkit-line-clamp: 1;
      }

      .new-data {
        font-size: 12px;
        color: rgb(87, 87, 87);
        display: -webkit-box;
        // 不换行
        overflow: hidden;
        -webkit-box-orient: vertical;
        // 俩点S
        -webkit-line-clamp: 1;
      }
    }

    .date {
      flex: 3;
      font-size: 12px;
      line-height: 50px;
      color: rgb(87, 87, 87);
    }
  }
}
</style>