<template>
  <div class="userwrap">
       <div class="view">
         <router-view></router-view>
       </div>
       <div class="users-wrap">
          <friendList />
       </div>
  </div>
</template>

<script>
import friendList from './firendList.vue'

export default {
    name:'user',
    components:{friendList},
    data() {
      return {
        
      }
    },
}
</script>

<style lang='less' scoped>

.userwrap{
  width: 100%;
  height: 100%;
  // overflow: hidden;s

  .view{
    float: left;
    width: calc(100% - 263px);
    height: 100%;
  }
  .users-wrap{
    position: fixed;
    top: 30px;
    right: 20px;
    width: 263px;
    height: calc(100% - 60px);
    background: rgb(255, 255, 255);
  }
}
</style>